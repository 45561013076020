<template>
  <div
    class="news_desc con_area_bgcolor con_area"
    v-loading="loading"
    :element-loading-text="loadText"
  >
    <div class="w1400">
      <div class="news_desc_con">
        <h2>
          {{ info.title }}
        </h2>
        <div class="news_desc_con_main">
          <p class="justify_align" v-html="info.extJson"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sysApi from "@/api/sys/articles/sysApi";

export default {
  data() {
    return {
      loading: false,
      loadText: "加载中",
      info: {},
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.getList(to.query.id);
      },
    },
  },
  // created() {
  //   this.getList(this.$route.query.id);
  // },
  methods: {
    // 根据id获取数据
    async getList(id) {
      this.loading = true;
      const item = {
        articleid: id,
      };
      try {
        const resultData = await sysApi.GetArticlesByID(item); //这里token存储，放到请求拦截裂存储
        this.info = resultData;
      } catch (err) {
        this.$message({
          type: "error",
          message: err,
          offset: 100,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.news_desc {
  width: 100%;
}
.news_desc .w1400 {
  width: 140rem;
  margin: 0 auto;
}
.news_desc h2 {
  font-size: 2.5rem;
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: center;
}
.news_desc_con_main p {
  font-size: 1.9rem;
}
.news_desc_con_main img {
  max-width: 100%;
  height: auto;
}
</style>